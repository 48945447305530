<template>
  <v-container>
    <PurposeList/>
  </v-container>
</template>

<script>
import PurposeList from "@/components/app_setting/purpose/PurposeList";

export default {
  name: "PurposePage",
  components: {
    PurposeList,
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Purpose'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>